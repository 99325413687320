<template>
    <b-card>
      <form @submit.prevent="validateBeforeSubmit">
          <h1>Add Project Manager</h1>
          <p class="text-muted">Enter project manager information</p>
          <label for="">First Name</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
          </b-input-group>
          <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
          <label for="">Last Name</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
          </b-input-group>
          <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
  
  
          <hr>
          <label for="">Phone Number</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
          </b-input-group>
          <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>
  
          <label for="">Fax Number</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="number" v-model="fax" name="fax" class="form-control" placeholder="Fax Number" />
          </b-input-group>
  
          <hr>
          <label for="">Email</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
          </b-input-group>
          <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
  
          <label for="">Password</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="password" v-model="password" v-validate="'required'" name="password" class="form-control" placeholder="Password" />
          </b-input-group>
          <i v-show="errors.has('password')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('password')" class="help is-danger">The password is required.</span>
  
          <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error. Please contact admin. </span><span v-show="unique" class="help is-danger">Email already exists.</span>
          <b-button variant="success" class="mt-4" type="submit" block>Create Project Manager</b-button>
        </form>
    </b-card>
  </template>
  
  
  <script>
  export default {
    name: 'ManagerAddWts',
    data: function () {
      return {
        email: "",
        password: "",
        fname: "",
        lname: "",
        fax: "",
        phone: "",
        error: false,
        unique: false,
      }
    },
    methods: {
      validateBeforeSubmit() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            let data = JSON.stringify({
              companyid: this.companyid,
              email: this.email,
              password: this.password,
              authority: this.authority,
              passlimit: this.passlimit,
              fname: this.fname,
              lname: this.lname,
              fax: this.fax,
              phone: this.phone,
            })
            this.$http.post('/sys_admin/create_wts', data)
            .then(response => {
              this.$toasted.show('Project Manager Added Successfully', {type: 'success', duration: '3000'});
              this.$router.push('wts');
            })
            .catch(error => {
              if(error.response.data.details == "email_UNIQUE must be unique"){
                this.unique = true;
              }
              this.$toasted.show('Error', {type: 'error', duration: '3000'})
              this.error = true;
            })
            return;
          }
        });
      }
    }
  }
  </script>
  
  <style lang="scss">
    label {
      display:block;
      margin-top: 0.5em
    }
    .fa-exclamation-triangle {
      color: #ffc107;
      margin-right: 0.5em;
    }
  </style>
  